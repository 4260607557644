<template>
  <div>
    <el-row style="margin-bottom: 20px;">
      <el-col :span="17">
        <el-card style="margin-bottom: 10px">
          <search-filter
              :searchName="'搜索题目'"
              :keywordOptions="{placeholder:'请输入题目名称或题目编号'}"
              :searchOptions="[
                { name:'el-form-item' ,
                options:{label:'难度' , prop:'diff_id', type:'radio' , data:{ name:'question', keys:'diff_id' } , defaultValue :'全部' }
                },
                { name:'el-form-item' ,
                options:{label:'来源' , prop:'from_id', type:'radio'  , data:{ name:'question', keys:'from_id' } , defaultValue :'全部'}
                },
                { name:'el-form-item' ,
                options:{label:'类型' , prop:'type', type:'radio' , data:{ name:'question', keys:'type2' } , defaultValue :'全部'}
                },
              ]"
              :otherOptions="[
                { name:'el-form-item' ,
                  options:{label:'时间' , prop:'time_str', type:'year' , placeholder:'请选择时间'}
                },
              ]"
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
              @eleChange="(v)=>{knowPointsHeight = v ? 280 : 100}"
          ></search-filter>
        </el-card>
        <div class="operate">
          <el-button-group>
            <el-button type="primary" size="small" :disabled="!$isPowers('questionCreate')" @click="$handleRoute({},'teachingQuestionCreate')">创建题目</el-button>
          </el-button-group>
        </div>
      </el-col>
      <el-col :span="6" :offset="1">
        <know-points :height="knowPointsHeight"></know-points>
      </el-col>
    </el-row>
    <el-card>
      <com-table
          ref="table"
          :data="tableData"
          :columns="columns"
          :total.sync="total"
          :auto-load="false"
          :edit-method="handleEditChange"
      >
        <template slot="action" slot-scope="scope">
          <el-button @click="$handleRoute({question_id:scope.row.id},'teachingQuestionInfo')" type="text" size="small">查看</el-button>
          <el-button type="text" size="small" :disabled="!$isPowers('questionEdit')" @click="$handleRoute({question_id:scope.row.id},'teachingQuestionEdit')">编辑</el-button>
          <el-button type="text" :disabled="!$isPowers('questionEdit') || scope.row.type != 1" size="small" @click="$handleRoute({question_id:scope.row.id},'teachingQuestionUseCases')">测试用例</el-button>
          <el-button type="text" size="small" @click="$handleRoute({question_id:scope.row.id},'teachingQuestionCensus')" :disabled="!$isPowers('questionCensus')">答题统计</el-button>
          <el-button :disabled="!$isPowers('questionDel')" @click="$handleConfirm('你确定删除此题目吗？','/xapi/question.question/delete',{id:scope.row.id} , ()=>{$refs.table.reset();})" type="text" size="small">删除</el-button>
        </template>
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import KnowPoints from "@/views/api/com/knowPoints";
export default {
  name: "QuestionList",
  components: {KnowPoints, SearchFilter},
  data(){
    var _this = this;
    return {
      knowPointsHeight:280,
      where:{},
      columns:[
        {prop:'id',label:'编号',width:'80' , align:'center'},
        {prop:'title',label:'<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',template:(scope)=>{
            // return '<font style="color:red;">'+scope.row.type_str+'</font> - <font style="color:green;">'+`${scope.row.user_nickname}? ${scope.row.user_nickname}:${scope.row.user_name}`+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
            return '<font style="color:red;">'+scope.row.type_str+'</font> - <font style="color:green;">'+`${scope.row.user_nickname?scope.row.user_nickname:scope.row.user_name}`+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
          },minWidth:'320px' , align:'left'},
        {prop:'grade',label:'分数',width:'80' , align:'center'},
        {prop:'time_str',label:'时间',width:'110' , align:'center'},
        {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
        {prop:'recommend',label:'是否推荐',width:'110' , slot:'switch' , align:'center',options:{activeValue:1,inactiveValue:0},callback(props){props.disabled = !_this.$isPowers('questionEdit')}},
        {prop:'valid',label:'是否有效',width:'110' , slot:'switch' , align:'center',callback(props){props.disabled = !_this.$isPowers('questionEdit')}},
        {prop:'show_type',label:'显示状态',width:'110' , slot:'switch' , align:'center'},
        {fixed:'right',label:'操作',width:'240' , slot:'action' , align:'center'},
      ],
      total:0,
      tableData: {
        url:'/xapi/question.question/myList',
        params:{}
      }
    }
  },
  methods: {
    routeUpdate(){
      this.tableData.params = this.$route.query || {};
    },
    handleEditChange(v , ov , {row , column}){
      this.$http.post('/xapi/question.question/updateField',{
        field:column.prop,
        id:row.id,
        value:v
      }).catch(()=>{
        row[column.prop] = ov;
      })
    }
  },
}
</script>

<style scoped>

</style>